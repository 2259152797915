import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import Layout from "../src/Layout";
import { Meetings } from "../src/meetings/Meetings";
import { MeetingForm } from "../src/meeting_form/MeetingForm";
import { Integrations } from "../src/settings/integrations/Integrations";
import { Settings } from "../src/settings/Settings";
import { OrganizationView } from "../src/settings/organization/OrganizationView";
import { FieldConfigurations } from "../src/settings/field_configurations/FieldConfigurations";
import { Workflows } from "../src/settings/workflows/Workflows";
import { Teams } from "../src/settings/teams/Teams";
import { Organizations } from "../src/settings/all_organizations/AllOrganizations";
import { Products } from "../src/settings/products/Products";
import { Workflow } from "../src/settings/workflows/Workflow";
import { Onboarding } from "../src/onboarding/Onboarding";
import { TeamMeetings } from "../src/team/TeamMeetings";
import { FieldConfigurationPage } from "../src/settings/field_configurations/field_configuration/FieldConfigurationPage";
import { UserRole } from "./enums";
import { MeetingDrawer } from "../src/team/MeetingDrawer";
import { Button, Result, Space } from "antd";
import { AppProps } from "../src/App";
import { fetchSignOut } from "./api";
import { WorkflowInstanceDrawer } from "../src/settings/workflows/WorkflowInstanceDrawer";
import { TeamDrawer } from "../src/settings/teams/TeamDrawer";
import { WorkflowEditor } from "../src/settings/workflows/WorkflowEditor";
import { WorkflowStateEditorDrawer } from "../src/settings/workflows/shared/WorkflowStateEditorDrawer";
import { WorkflowTransitionEditorDrawer } from "../src/settings/workflows/shared/WorkflowTransitionEditorDrawer";
import { ReactFlowProvider } from "reactflow";

export const getRouter = ({
  user_roles,
  is_user_onboarded,
  authenticity_token,
}: AppProps) => {
  const getInitialRoute = () => {
    if (user_roles.includes(UserRole.User)) {
      return is_user_onboarded
        ? "/dashboard/meetings"
        : "/dashboard/onboarding";
    }

    if (user_roles.includes(UserRole.Collaborator)) {
      return "/dashboard/team-meetings";
    }

    if (user_roles.includes(UserRole.Admin)) {
      return "/dashboard/settings/organization";
    }

    if (user_roles.includes(UserRole.TechnicalAdmin)) {
      return "/dashboard/settings/field-configurations";
    }

    if (user_roles.includes(UserRole.SuperAdmin)) {
      return "/dashboard/settings/all-organizations";
    }

    return "/dashboard/integrations";
  };

  return createBrowserRouter([
    {
      path: "/dashboard",
      errorElement: (
        <Result
          status="500"
          title="Uh oh, something went wrong"
          subTitle="Try visiting the home page or re-signing in."
          extra={
            <Space>
              <Button type="primary" href="/dashboard/meetings">
                Back Home
              </Button>

              <Button
                onClick={async () => {
                  await fetchSignOut(authenticity_token);
                  window.location.reload();
                }}
              >
                Sign Out
              </Button>
            </Space>
          }
        />
      ),
      children: [
        {
          index: true,
          element: <Navigate to={getInitialRoute()} replace />,
        },
        {
          path: "/dashboard/onboarding",
          element: <Onboarding />,
        },

        {
          element: <Layout />,
          children: [
            {
              path: "/dashboard/meetings",
              element: <Meetings />,
            },
            {
              path: "/dashboard/team-meetings",
              element: <TeamMeetings />,
              children: [
                {
                  path: "/dashboard/team-meetings/:meetingId",
                  element: <MeetingDrawer />,
                },
              ],
            },

            {
              path: "/dashboard/meetings/:meetingId",
              element: <MeetingForm />,
            },
            {
              path: "/dashboard/integrations",
              element: <Integrations />,
            },
            {
              path: "/dashboard/settings",
              element: <Settings />,
              children: [
                // Organization
                {
                  path: "organization",
                  element: <OrganizationView />,
                },
                {
                  path: "teams",
                  element: <Teams />,
                  children: [
                    {
                      path: "/dashboard/settings/teams/:teamUuid",
                      element: <TeamDrawer />,
                    },
                  ],
                },
                {
                  path: "field-configurations",
                  element: <FieldConfigurations />,
                },
                {
                  path: "field-configurations/:fieldUuid",
                  element: <FieldConfigurationPage />,
                },
                {
                  path: "workflows",
                  element: <Workflows />,
                },
                {
                  path: "workflows/:workflowUuid",
                  element: <Workflow />,
                  children: [
                    {
                      path: "workflow-instances/:workflowInstanceUuid",
                      element: <WorkflowInstanceDrawer />,
                    },
                  ],
                },
                {
                  path: "workflows/:workflowUuid/editor",
                  element: (
                    <ReactFlowProvider>
                      <WorkflowEditor />
                    </ReactFlowProvider>
                  ),
                  children: [
                    {
                      path: "workflow-states/:workflowStateUuid",
                      element: <WorkflowStateEditorDrawer />,
                    },
                    {
                      path: "workflow-states/:workflowStateUuid/new",
                      element: <WorkflowStateEditorDrawer isNew />,
                    },
                    {
                      path: "workflow-states/:workflowStateUuid/workflow-transitions/:workflowTransitionUuid",
                      element: <WorkflowTransitionEditorDrawer />,
                    },
                    {
                      path: "workflow-states/:workflowStateUuid/workflow-transitions/new",
                      element: <WorkflowTransitionEditorDrawer isNew />,
                    },
                  ],
                },

                // Swyft Admin
                {
                  path: "all-organizations",
                  element: <Organizations />,
                },
                {
                  path: "products",
                  element: <Products />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
};
