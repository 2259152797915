import React, { useEffect, useState } from "react";
import { Button, Form, message, Modal, Space } from "antd";
import {
  AutoGeneratedField,
  CRMField,
  FieldConfiguration,
} from "../../../util/types";
import { useForm } from "antd/es/form/Form";
import { parseCRMValue } from "../../../util/parseCRMValue";
import { convertContentToJson } from "../../../util/auto_generated_field_helpers";
import { MeetingFormFieldItem } from "../MeetingFormFieldItem";

interface AutoGeneratedFieldEditorModalProps {
  open: boolean;
  containerId: string;
  autoGeneratedField: AutoGeneratedField;
  fieldConfiguration: FieldConfiguration;
  crmField?: CRMField;
  setOpen: (open: boolean) => void;
  handleAutoGeneratedFieldUpdate: (
    attributes: Partial<AutoGeneratedField>
  ) => void;
}

export function AutoGeneratedFieldEditorModal({
  open,
  containerId,
  autoGeneratedField,
  fieldConfiguration,
  crmField,
  setOpen,
  handleAutoGeneratedFieldUpdate,
}: AutoGeneratedFieldEditorModalProps) {
  const [form] = useForm();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const fieldName = crmField
    ? crmField.crm_field_name
    : fieldConfiguration.name;

  const fieldtype = crmField
    ? crmField.crm_field_type
    : fieldConfiguration.field_type;

  const parsedAutoGeneratedValue = parseCRMValue(
    autoGeneratedField.field_configuration.field_type,
    autoGeneratedField.content
  );

  useEffect(() => {
    if (!open) return;

    const value = autoGeneratedField.content_present
      ? parsedAutoGeneratedValue
      : parseCRMValue(fieldtype, crmField?.crm_field_value);

    form.setFieldsValue({ [fieldName]: value });
  }, [open]);

  const handleSubmit = async () => {
    try {
      setIsSaving(true);

      await handleAutoGeneratedFieldUpdate({
        accepted: fieldConfiguration.crm_managed ? true : null,
        content: form.getFieldValue(fieldName),
        user_modified_content: convertContentToJson(
          fieldtype,
          form.getFieldValue(fieldName)
        ),
      });

      setOpen(false);
    } catch (e) {
      message.error("An error occurred. Please try again.");
    }

    setIsSaving(false);
  };

  const handleCancel = () => setOpen(false);

  return (
    <Modal
      getContainer={document.getElementById(containerId)}
      title="Edit Field"
      open={open}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      maskClosable={false}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <MeetingFormFieldItem
          containerId={containerId}
          name={fieldName}
          fieldType={fieldtype}
          label={crmField ? crmField.crm_field_label : fieldConfiguration.label}
          required={
            crmField ? crmField.crm_field_required : fieldConfiguration.required
          }
          length={
            crmField ? crmField.crm_field_length : fieldConfiguration.length
          }
          picklistItems={
            crmField
              ? crmField.crm_field_picklist_values
              : fieldConfiguration.picklist
          }
          placeholderText="Enter a value..."
        />

        <Space style={{ marginTop: 15 }}>
          <Form.Item>
            <Button loading={isSaving} htmlType="submit" type="primary">
              Save
            </Button>
          </Form.Item>

          <Form.Item>
            <Button onClick={handleCancel}>Cancel</Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
}
