import React, { useContext, useState } from "react";
import {
  useFindCRMRecordById,
  useLayoutedFieldConfigurationGroups,
  useMeeting,
  useOrganizationMeetings,
} from "../../util/data_hooks";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { getContentPreview } from "../../util/auto_generated_field_helpers";
import { getFormattedDate } from "../../helpers/date_helpers";
import { MeetingStatus } from "../shared/MeetingStatus";
import { CRMRecordTag } from "../shared/CRMRecordTag";
import { EmailAvatarLabel } from "../shared/EmailAvatarLabel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { parseCRMValue } from "../../util/parseCRMValue";
import Markdown from "react-markdown";
import { SwyftIcon } from "../shared/SwyftIcon";
import { SyncedCRMObject } from "../../util/types";
import { usePageTitle } from "../hooks/usePageTitle";
import { CheckCircleOutlined, StopOutlined } from "@ant-design/icons";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import SkeletonInput from "antd/es/skeleton/Input";
import { AppContext } from "../App";
import { useSearchParamsState } from "../hooks/useSearchParamsState";
import { MeetingsTableParam } from "../shared/MeetingsTable";

function RecordLink({
  syncedCRMObject,
  crmRecordName,
  crmRecordId,
  autoAssociated = false,
}: {
  syncedCRMObject: SyncedCRMObject;
  crmRecordName: string;
  crmRecordId: string;
  autoAssociated?: boolean;
}) {
  const { record, isLoading } = useFindCRMRecordById(
    syncedCRMObject.crm_object_type,
    syncedCRMObject.searchable_crm_field_name,
    crmRecordId
  );

  if (isLoading) return null;

  return (
    <CRMRecordTag
      autoAssociated={autoAssociated}
      showBackground
      crm={syncedCRMObject.crm_provider_name}
      name={`${crmRecordName} (${syncedCRMObject.crm_object_label})`}
      url={record.crm_record_url}
    />
  );
}

function FormattedFieldValue({
  label,
  value,
  accepted,
  context,
}: {
  label: string;
  value: string;
  context: string;
  accepted: boolean | null;
}) {
  let statusTag;

  if (accepted === true) {
    statusTag = (
      <Tag color="success" icon={<CheckCircleOutlined />}>
        Approved
      </Tag>
    );
  } else if (accepted === false) {
    statusTag = (
      <Tag color="error" icon={<StopOutlined />}>
        Rejected
      </Tag>
    );
  }

  const markdownComponents = {
    p: ({ ...rest }) => {
      return (
        <p style={{ fontSize: 14, marginTop: 0, lineHeight: 1.5 }} {...rest} />
      );
    },
    li: ({ ...rest }) => (
      <li
        style={{
          fontSize: 14,
          lineHeight: 1.5,
          paddingBottom: 5,
        }}
        {...rest}
      />
    ),
    ul: ({ ...rest }) => (
      <ul style={{ paddingLeft: 15, marginTop: 0 }} {...rest} />
    ),
    ol: ({ ...rest }) => (
      <ul style={{ paddingLeft: 15, marginTop: 0 }} {...rest} />
    ),
  };

  return (
    <Card title={label} size="small" extra={statusTag}>
      <Markdown components={markdownComponents}>
        {value || "*Not discussed*"}
      </Markdown>

      {context && (
        <>
          <Divider style={{ margin: "10px 0px" }}></Divider>

          <Typography.Paragraph
            type="secondary"
            style={{ fontSize: 12 }}
            ellipsis={{
              rows: 2,
              expandable: "collapsible",
            }}
          >
            <b>Context:</b> {context}
          </Typography.Paragraph>
        </>
      )}
    </Card>
  );
}

export function MeetingDrawer() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const context = useContext(AppContext);
  const { meetingId } = useParams();

  const isImpersonating = !!context.user_impersonation_email;

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { meeting, isLoading: isMeetingLoading } = useMeeting(meetingId);

  const [page, setPage] = useSearchParamsState(MeetingsTableParam.Page, "1");
  const [pageSize] = useSearchParamsState(MeetingsTableParam.PageSize, "10");

  const {
    layoutedFieldConfigurationGroups,
    isLoading: isLayoutedFieldConfigurationGroupsLoading,
  } = useLayoutedFieldConfigurationGroups(meetingId, {
    requestPredictions: false,
    fieldStatus: "automated",
    teamUuid: meeting?.user_team_uuid,
  });

  const {
    organizationMeetings,
    meta,
    isLoading: isMeetingsLoading,
  } = useOrganizationMeetings({
    page: parseInt(page) - 1,
    pageSize: parseInt(pageSize),
  });

  usePageTitle(meeting?.topic);

  const currentIndex = organizationMeetings?.findIndex(
    ({ uuid }) => uuid === meeting?.uuid
  );

  const isLoading =
    isMeetingLoading ||
    isMeetingsLoading ||
    isLayoutedFieldConfigurationGroupsLoading;

  return (
    <Drawer
      destroyOnClose
      title={
        isLoading ? (
          <SkeletonInput />
        ) : (
          <Row align="middle" justify="space-between">
            <Col>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {meeting?.topic}
              </Typography.Title>
            </Col>

            <Col>
              <Space>
                <Button
                  disabled={currentIndex === 0 && parseInt(page) === 1}
                  onClick={() => {
                    const prevInstance = organizationMeetings[currentIndex - 1];
                    if (!prevInstance) {
                      navigate(`/dashboard/team-meetings`);
                      setPage((parseInt(page) - 1).toString());
                      return;
                    }

                    navigate(
                      `/dashboard/team-meetings/${prevInstance.uuid}${search}`
                    );
                  }}
                  shape="circle"
                  icon={<LeftOutlined />}
                />

                <Button
                  disabled={
                    currentIndex === organizationMeetings?.length - 1 &&
                    meta.page === meta.total_pages
                  }
                  onClick={() => {
                    const nextInstance = organizationMeetings[currentIndex + 1];
                    if (!nextInstance) {
                      navigate(`/dashboard/team-meetings`);
                      setPage((parseInt(page) + 1).toString());
                      return;
                    }

                    navigate(
                      `/dashboard/team-meetings/${nextInstance.uuid}${search}`
                    );
                  }}
                  shape="circle"
                  icon={<RightOutlined />}
                />
              </Space>
            </Col>
          </Row>
        )
      }
      placement="right"
      width="75rem"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      afterOpenChange={(open) =>
        !open && navigate(`/dashboard/team-meetings${search}`)
      }
      loading={isLoading}
    >
      <Descriptions size="middle" colon={false} layout="vertical" column={2}>
        <Descriptions.Item label="User">
          {meeting && <EmailAvatarLabel email={meeting.user_email} />}
        </Descriptions.Item>

        <Descriptions.Item label="When">
          <Typography.Text>
            {getFormattedDate(meeting?.start_time)}
          </Typography.Text>
        </Descriptions.Item>

        <Descriptions.Item label="Associated Records">
          {meeting?.associated_crm_records.length ? (
            <Row gutter={[6, 6]}>
              {meeting.associated_crm_records.map(
                ({
                  crm_record_id,
                  crm_record_name,
                  synced_crm_object,
                  auto_associated,
                }) => (
                  <Col key={crm_record_id}>
                    <CRMRecordTag
                      crm={synced_crm_object.crm_provider_name}
                      name={`${crm_record_name} (${synced_crm_object.crm_object_label})`}
                      autoAssociated={auto_associated}
                    />
                  </Col>
                )
              )}
            </Row>
          ) : (
            <Typography.Text italic>Not selected</Typography.Text>
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Status">
          <MeetingStatus meeting={meeting} />
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      {layoutedFieldConfigurationGroups.map(
        ({
          field_configuration_group,
          associated_crm_record,
          layouted_fields,
        }) => {
          return (
            <div
              key={field_configuration_group.uuid}
              style={{ marginBottom: 25 }}
            >
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Space size="small">
                    <Typography.Title level={3} style={{ margin: 0 }}>
                      {field_configuration_group.label}
                    </Typography.Title>

                    {!field_configuration_group.synced_crm_object && (
                      <SwyftIcon inverted width={30} />
                    )}
                  </Space>
                </Col>

                {associated_crm_record && !isImpersonating && (
                  <Col>
                    <RecordLink
                      syncedCRMObject={
                        field_configuration_group.synced_crm_object
                      }
                      crmRecordId={associated_crm_record.crm_record_id}
                      crmRecordName={associated_crm_record.crm_record_name}
                      autoAssociated={associated_crm_record.auto_associated}
                    />
                  </Col>
                )}

                {associated_crm_record?.last_synced_at && (
                  <Col span={24}>
                    <Typography.Text type="secondary">
                      Manually synced to CRM on{" "}
                      {getFormattedDate(associated_crm_record.last_synced_at)}
                    </Typography.Text>
                  </Col>
                )}

                {associated_crm_record?.auto_synced_at && (
                  <Col span={24}>
                    <Typography.Text type="secondary">
                      Auto-Synced to CRM on{" "}
                      {getFormattedDate(associated_crm_record.auto_synced_at)}.{" "}
                      {associated_crm_record?.workflow_instance
                        ?.workflow_uuid && (
                        <Typography.Link
                          href={`/dashboard/settings/workflows/${associated_crm_record?.workflow_instance?.workflow_uuid}/workflow-instances/${associated_crm_record?.workflow_instance?.uuid}`}
                          target="_blank"
                        >
                          View Workflow
                        </Typography.Link>
                      )}
                    </Typography.Text>
                  </Col>
                )}

                <Col span={24}>
                  <Row gutter={[30, 30]}>
                    {layouted_fields.length > 0 ? (
                      layouted_fields.map(
                        ({ field_configuration, auto_generated_field }) => {
                          const parsedContent = parseCRMValue(
                            field_configuration.field_type,
                            auto_generated_field?.content || null
                          );

                          const previewableContent = getContentPreview(
                            parsedContent,
                            field_configuration.field_type,
                            field_configuration.picklist
                          );

                          if (!field_configuration.automated) return null;

                          return (
                            <Col
                              key={field_configuration.uuid}
                              span={previewableContent.length > 500 ? 24 : 12}
                            >
                              <FormattedFieldValue
                                label={field_configuration.label}
                                value={previewableContent}
                                accepted={auto_generated_field?.accepted}
                                context={
                                  previewableContent
                                    ? auto_generated_field?.context
                                    : null
                                }
                              />
                            </Col>
                          );
                        }
                      )
                    ) : (
                      <Col>
                        <Typography.Text italic>No Fields</Typography.Text>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>

              <Divider />
            </div>
          );
        }
      )}
    </Drawer>
  );
}
