import { CRMFieldType } from "./enums";
import { AutoGeneratedField, CRMField, PicklistItem } from "./types";
import { diffSentences, diffWordsWithSpace } from "diff";
import moment from "moment";

const decodeHtmlEntities = (string: string) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = string;
  return textarea.value;
};

export const getContentPreview = (
  value: any,
  type: CRMFieldType,
  picklistOptions: PicklistItem[] = []
): string => {
  switch (type) {
    case CRMFieldType.Boolean:
      if (value === null || value === undefined) return "";
      return value ? "Yes" : "No";
    case CRMFieldType.Multipicklist:
      if (value === null) return "";
      return value.length > 0 ? value.sort().join(", ") : "";
    case CRMFieldType.Picklist: {
      const label = picklistOptions.find(
        (picklistOption) => picklistOption.value === value
      )?.label;

      return label || value || "";
    }
    case CRMFieldType.Date:
      if (value === null) return "";
      if (moment.isMoment(value)) {
        return value.format("MM/DD/YY");
      } else {
        return moment(value).format("MM/DD/YY");
      }
    case CRMFieldType.Time:
      if (value === null) return "";
      if (moment.isMoment(value)) {
        return value.format("h:mm A");
      } else {
        return moment(value).format("h:mm A");
      }
    case CRMFieldType.DateTime:
      if (value === null) return "";
      if (moment.isMoment(value)) {
        return value.format("dddd, MM/DD/YY, h:mm A");
      } else {
        return moment(value).format("dddd, MM/DD/YY, h:mm A");
      }
    case CRMFieldType.HTML:
      if (value === null) return "";

      return decodeHtmlEntities(
        value
          .replace(/<br\s*\/?>/gi, "\n")
          .replace(/<\/p>/gi, "\n")
          .replace(/<p[^>]*>/gi, "\n")
          .replace(/<\/?[^>]+(>|$)/g, "")
      );
    case CRMFieldType.Currency:
    case CRMFieldType.Double:
    case CRMFieldType.Percent:
      return value === null ? "" : value.toString();
    default:
      return value ? value.toString() : "";
  }
};

export const convertContentToJson = (type: CRMFieldType, value: any) => {
  switch (type) {
    case CRMFieldType.String:
    case CRMFieldType.EncryptedString:
    case CRMFieldType.Textarea:
    case CRMFieldType.HTML:
    case CRMFieldType.Double:
    case CRMFieldType.Currency:
    case CRMFieldType.Percent:
      return value;
    default:
      return JSON.stringify(value);
  }
};

export interface DiffInformation {
  value: string;
  type: "equal" | "added" | "removed";
}

export const getAutoGeneratedFieldDiffs = (
  autoGeneratedField: AutoGeneratedField,
  crmField: CRMField
) => {
  const crmContent = getContentPreview(
    crmField.crm_field_value,
    crmField.crm_field_type,
    crmField.crm_field_picklist_values
  );

  const autoGeneratedContent = getContentPreview(
    autoGeneratedField.content,
    autoGeneratedField.field_configuration.field_type,
    autoGeneratedField.field_configuration.picklist
  );

  const shouldDiffSentences =
    crmContent.length > 500 || autoGeneratedContent.length > 500;

  const diffArray = shouldDiffSentences
    ? diffSentences(crmContent, autoGeneratedContent)
    : diffWordsWithSpace(crmContent, autoGeneratedContent);

  const leftDiff: DiffInformation[] = [];
  const rightDiff: DiffInformation[] = [];

  diffArray.forEach(({ added, removed, value }) => {
    const type = removed ? "removed" : added ? "added" : "equal";

    if (removed) leftDiff.push({ value, type });
    if (added) rightDiff.push({ value, type });

    if (!removed && !added && value) {
      rightDiff.push({ value, type });
      leftDiff.push({ value, type });
    }
  });

  return { leftDiff, rightDiff };
};

export const isFieldTypeAutomated = (fieldType: CRMFieldType): boolean => {
  return [
    CRMFieldType.String,
    CRMFieldType.Textarea,
    CRMFieldType.HTML,
    CRMFieldType.Currency,
    CRMFieldType.Double,
    CRMFieldType.Percent,
    CRMFieldType.Boolean,
    CRMFieldType.Picklist,
    CRMFieldType.Multipicklist,
    CRMFieldType.Date,
    CRMFieldType.DateTime,
  ].includes(fieldType);
};
