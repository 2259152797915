import React, { useState } from "react";
import { Button, Col, Drawer, Row, Space, Table, Tabs, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFieldConfigurationGroupLayouts,
  useOrganization,
  useTeam,
  useTeams,
} from "../../../util/data_hooks";
import SkeletonInput from "antd/es/skeleton/Input";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { User } from "../../../util/types";
import { ColumnsType } from "antd/es/table";
import { green, red } from "@ant-design/colors";
import { TeamEditorForm } from "./TeamEditorForm";
import { FieldConfigurationGroupsLayoutTable } from "./FieldConfigurationGroupsLayoutTable";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSelectedTab } from "../../redux/features/team/teamDrawerTab";
import { TeamDrawerTab } from "../../../util/enums";

export function TeamDrawer() {
  const navigate = useNavigate();
  const { teamUuid } = useParams();
  const dispatch = useAppDispatch();

  // STATE
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const tab = useAppSelector((state) => state.teamDrawerTab.selectedTab);

  // DATA
  const { organization, isLoading: organizationLoading } =
    useOrganization(null);
  const { teams, isLoading: teamsLoading } = useTeams();
  const {
    team,
    isLoading: teamLoading,
    mutate: mutateTeam,
  } = useTeam(teamUuid);
  const {
    fieldConfigurationGroupLayouts,
    isLoading: fieldConfigurationGroupLayoutsLoading,
    mutate: mutateLayout,
  } = useFieldConfigurationGroupLayouts(team);

  const isLoading =
    teamLoading ||
    teamsLoading ||
    organizationLoading ||
    fieldConfigurationGroupLayoutsLoading;
  const currentIndex = teams?.findIndex(({ uuid }) => uuid === team?.uuid);

  const columns: ColumnsType<User> = [
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: `CRM Role`,
      dataIndex: ["crm_role", "name"],
    },
    {
      title: `Active`,
      dataIndex: "active",
      align: "center",
      render: (active) =>
        active ? (
          <CheckCircleTwoTone twoToneColor={green.primary} />
        ) : (
          <CloseCircleTwoTone twoToneColor={red.primary} />
        ),
    },
  ];
  return (
    <Drawer
      destroyOnClose
      title={
        isLoading ? (
          <SkeletonInput />
        ) : (
          <Row align="middle" justify="space-between">
            <Col>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {team?.name}
              </Typography.Title>
            </Col>

            <Col>
              <Space>
                <Button
                  disabled={currentIndex === 0}
                  onClick={() => {
                    const prevInstance = teams[currentIndex - 1];
                    if (!prevInstance) return;
                    navigate(`/dashboard/settings/teams/${prevInstance.uuid}`);
                  }}
                  shape="circle"
                  icon={<LeftOutlined />}
                />

                <Button
                  onClick={() => {
                    const nextInstance = teams[currentIndex + 1];
                    if (!nextInstance) return;
                    navigate(`/dashboard/settings/teams/${nextInstance.uuid}`);
                  }}
                  disabled={currentIndex === teams?.length - 1}
                  shape="circle"
                  icon={<RightOutlined />}
                />
              </Space>
            </Col>
          </Row>
        )
      }
      placement="right"
      width="75rem"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      afterOpenChange={(open) => !open && navigate("/dashboard/settings/teams")}
      loading={isLoading}
    >
      <Tabs
        defaultActiveKey={tab}
        onChange={(key: TeamDrawerTab) => {
          dispatch(setSelectedTab(key));
        }}
        items={[
          {
            key: TeamDrawerTab.Edit,
            label: "Edit Team",
            children: (
              <TeamEditorForm
                team={team}
                organization={organization}
                afterSave={() => {
                  mutateTeam();
                }}
              />
            ),
          },
          {
            key: TeamDrawerTab.Users,
            label: "Team Members",
            children: (
              <Table<User>
                rowKey={({ uuid }) => uuid}
                dataSource={team?.users}
                columns={columns}
                pagination={false}
              />
            ),
          },
          {
            key: TeamDrawerTab.Layout,
            label: "Meeting Layout",
            children: (
              <FieldConfigurationGroupsLayoutTable
                team={team}
                fieldConfigurationGroupLayouts={fieldConfigurationGroupLayouts}
                mutateLayout={mutateLayout}
              />
            ),
          },
        ]}
      />
    </Drawer>
  );
}
