import React, { useState } from "react";
import { Button, Form, Input, Modal, Select, Switch, message } from "antd";
import { fetchCreateWorkflow, fetchUpdateWorkflow } from "../../../../util/api";
import { useNavigate } from "react-router-dom";
import { getFriendlyWorkflowTriggerLabel } from "../../../../helpers/label_maps";
import { WorkflowTrigger } from "../../../../util/enums";
import { Team, Workflow } from "../../../../util/types";
import {
  useTeamFieldConfigurationGroups,
  useWorkflow,
} from "../../../../util/data_hooks";

interface WorkflowDetailsEditorModalProps {
  open: boolean;
  workflow?: Workflow;
  team: Team;
  setOpen: (open: boolean) => void;
}

export function WorkflowDetailsEditorModal({
  open,
  workflow,
  team,
  setOpen,
}: WorkflowDetailsEditorModalProps) {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate } = useWorkflow(workflow?.uuid);

  const [trigger, setTrigger] = useState<WorkflowTrigger>(workflow?.trigger);

  const { fieldConfigurationGroups } = useTeamFieldConfigurationGroups(
    team?.uuid
  );

  const [createWorkflowLoading, setCreateWorkflowLoading] =
    useState<boolean>(false);

  const handleCreateWorkflow = async (values) => {
    setCreateWorkflowLoading(true);

    try {
      if (workflow) {
        await fetchUpdateWorkflow(workflow.uuid, values);
        message.success(`Workflow saved`);
      } else {
        const newWorkflow = await fetchCreateWorkflow(team.uuid, values);
        message.success(`Workflow created`);
        navigate(`${newWorkflow.uuid}`);
      }

      mutate();
      setOpen(false);
    } catch (e) {
      message.error(e.message);
    }

    setCreateWorkflowLoading(false);
  };

  const onCancel = () => {
    form.resetFields();
    setTrigger(null);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      footer={null}
      title={workflow ? "Edit Workflow Details" : "Create Workflow"}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleCreateWorkflow}
        initialValues={{
          field_configuration_uuids: workflow?.field_configuration_triggers.map(
            ({ uuid }) => uuid
          ),
          ...workflow,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          style={{ width: "100%" }}
          rules={[{ required: true }]}
        >
          <Input placeholder="My awesome workflow" />
        </Form.Item>

        <Form.Item label="Enabled?" name="enabled">
          <Switch />
        </Form.Item>

        <Form.Item
          label="Trigger"
          name="trigger"
          style={{ width: "100%" }}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select a trigger"
            disabled={!!workflow}
            onChange={setTrigger}
            options={Object.values(WorkflowTrigger).map((value) => {
              return {
                value,
                label: getFriendlyWorkflowTriggerLabel(value),
              };
            })}
          />
        </Form.Item>

        {trigger === WorkflowTrigger.CRMRecordUpdated && (
          <Form.Item
            label="CRM Field Triggers"
            name="field_configuration_uuids"
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              mode="multiple"
              optionFilterProp="label"
              placeholder="Search for a CRM field"
              options={fieldConfigurationGroups
                .filter(({ synced_crm_object }) => !!synced_crm_object)
                .map(({ label, field_configurations }) => {
                  return {
                    label,
                    options: field_configurations.map(({ uuid, label }) => {
                      return {
                        value: uuid,
                        label,
                      };
                    }),
                  };
                })}
            />
          </Form.Item>
        )}

        <Button
          htmlType="submit"
          type="primary"
          loading={createWorkflowLoading}
        >
          {workflow ? "Save" : "Create Workflow"}
        </Button>
      </Form>
    </Modal>
  );
}
