import React from "react";
import { Form, Input, InputNumber, Select, Switch } from "antd";
import { CRMFieldType } from "../../util/enums";
import DatePicker from "../shared/DatePicker";
import TimePicker from "../shared/TimePicker";
import { PicklistItem } from "../../util/types";

interface Props {
  containerId?: string;
  cardIcon?: string;
  tooltipText?: string;
  onBlur?: (value) => void;
  placeholderText?: string;
  name: string | string[];
  label: string;
  fieldType: CRMFieldType;
  required: boolean;
  length: number;
  picklistItems: PicklistItem[];
  disabled?: boolean;
}

export function MeetingFormFieldItem({
  containerId,
  onBlur,
  name,
  label,
  fieldType,
  required,
  length,
  picklistItems,
  placeholderText,
  disabled = false,
}: Props) {
  const validationRules = [];

  if (required) validationRules.push({ required: true });

  let formItem = <></>;
  let valuePropName = "value";

  switch (fieldType) {
    case CRMFieldType.String:
    case CRMFieldType.Textarea:
    case CRMFieldType.HTML:
    case CRMFieldType.EncryptedString: {
      formItem = (
        <Input.TextArea
          placeholder={placeholderText || label}
          autoSize={{ maxRows: 19 }}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      validationRules.push({
        type: "string",
        max: length || 255,
        message: "${label} must be up to ${max} characters",
      });
      break;
    }
    case CRMFieldType.Phone: {
      formItem = (
        <Input
          placeholder={placeholderText || label}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      validationRules.push({
        type: "string",
        pattern: /^(\+\d{1,2}\s*)?\(?\d{3}\)?[\s.-]*\d{3}[\s.-]*\d{4}$/,
        message: "${label} must be a valid phone number (+1 234 567 8910)",
      });
      break;
    }
    case CRMFieldType.Email: {
      formItem = (
        <Input
          placeholder={placeholderText || label}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      validationRules.push({
        type: "email",
        message: "Please enter a valid email address",
      });
      break;
    }
    case CRMFieldType.Url: {
      formItem = (
        <Input
          placeholder={placeholderText || label}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      validationRules.push({
        type: "url",
        warningOnly: true,
        message: "Not a valid URL",
      });
      break;
    }
    case CRMFieldType.Date: {
      formItem = (
        <DatePicker
          placeholder={placeholderText || label}
          style={{ width: "100%" }}
          format="MM/DD/YYYY"
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      break;
    }
    case CRMFieldType.DateTime: {
      formItem = (
        <DatePicker
          placeholder={placeholderText || label}
          showTime
          use12Hours
          format="MM/DD/YYYY h:mm A"
          style={{ width: "100%" }}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      break;
    }
    case CRMFieldType.Time: {
      formItem = (
        <TimePicker
          placeholder={placeholderText || label}
          use12Hours
          format="h:mm A"
          style={{ width: "100%" }}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      break;
    }
    case CRMFieldType.Currency:
    case CRMFieldType.Double:
    case CRMFieldType.Percent: {
      formItem = (
        <InputNumber
          step={1}
          placeholder={placeholderText || label}
          style={{ width: "100%" }}
          changeOnWheel={false}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      break;
    }
    case CRMFieldType.Boolean: {
      formItem = <Switch onChange={onBlur} disabled={disabled} />;
      valuePropName = "checked";
      break;
    }
    case CRMFieldType.Picklist:
    case CRMFieldType.Reference: {
      formItem = (
        <Select
          getPopupContainer={() => document.getElementById(containerId)}
          options={picklistItems}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      break;
    }
    case CRMFieldType.Multipicklist: {
      formItem = (
        <Select
          getPopupContainer={() => document.getElementById(containerId)}
          mode="multiple"
          options={picklistItems}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      break;
    }
  }

  return (
    <Form.Item
      name={name}
      labelCol={{ span: 0 }}
      label={label}
      rules={validationRules}
      valuePropName={valuePropName}
    >
      {formItem}
    </Form.Item>
  );
}
